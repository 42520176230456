<script>
// import axios from "axios";
import Layout from "../../layouts/auth";
// import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";
import axios from 'axios';
import { required, email, helpers, maxLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";


import base_domain from '../../myvars'
axios.defaults.baseURL = base_domain
import { PublicClientApplication } from '@azure/msal-browser';
// import Loader from "../../../components/widgets/loader";

/**
 * Forgot Password component
 */
export default {
  setup() {
    return { v$: useVuelidate()  };
  },
  mounted() {
  },
  validations: {
    email: {
      required: helpers.withMessage("Email is required", required),
      email: helpers.withMessage("Please enter valid email", email),
    },
    maxlength: {
        required: helpers.withMessage("Maxlength is required", required),
        maxLength: maxLength(4),
    },
  },
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    // Loader,   
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
    // this.$emitter.on(
    //   'login',
    //   async function (account) {
    //     this.account = account;
    //     console.log(this.account);
    //     console.log('Getting storage data');
    //     await this.getAzureStorageData();
    //     await this.getAccessToken()
    //     this.tryToLogin()
    //   }.bind(this),
    // ),
    this.$emitter.on('logout', async function() {
      console.log('Logging out________');
      // this.account = undefined;
      await this.SignOut()
      console.log('Logging out', this.account);
    }.bind(this),)
  },
  data() {
    return {
      email: '',
      showForm1: true, // Initially show the first form
      showForm2: false,
      copiedEmail:'',
      isLoading: false,
      isDisabled:false,
      otp:'',
      toload:true,
      account: undefined,
      containers: [],
      myat: '',
      make_it_load:false,
      showErrorMessage:false,
      myerrors:'',
      signin: 'https://microsoft.com',
    };
  },
  methods: {
    async tryToReset(overide=false) {
      console.log("this.copiedEmail________________", this.email)
      if (!this.email) return
      overide = (overide == true) ? true : false 
      this.isLoading = true;
      this.isDisabled = true;
      // Make the API request with the entered email
      console.log('EMAIL IS THIS',this.email);
      console.log('EMAIL IS THIS',this.isLoading, overide);
      await axios.post('/accounts/otp_send/', { email: this.email, overide: overide, page: 'customer'})
        .then(response => {
          // Handle the response
          console.log('OTP sent successfully:', response.data);
          this.copiedEmail = this.email;
          if (response.data.loginby=="front"){
            this.showForm1 = false;
            this.showForm2 = true;
          }
          else{
            this.myerrors = "You account was not added with eastern"
            this.showErrorMessage = true
            setTimeout(() => {this.showErrorMessage = false}, 1500);
            this.isLoading = false
          }
        })
        .catch(error => {
          // Handle any errors
          console.error('Error sending email:', error);
        });
    },
    async tryToLogin() {
      let sr = this.copiedEmail
      console.log("Login API") 
      axios.post('/accounts/login/', { email: sr,otp:this.otp })
      .then(response => {
        // Handle the response
        console.log('response_____________',response.data.status, response.data); 
        if (response.data.status == "SUCCESS"){
            const accessToken = response.data.access;
            const role = response.data.role;
            const appStatus = response.data.app_status;
            const appStatusStage = response.data.app_status_stage;
            console.log('App status:',appStatus)
            console.log('App status:',appStatusStage)
            
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('userEmail', sr);
            
            if (role === 'applicant') {
              localStorage.setItem('userType', 'applicant');
              console.log('mai sharabi hu ---------',response.data.submit)
              if(response.data.submit === 'Yes'){
                this.$router.push({ name: 'default' });
              }
              else{
                this.$router.push({ name: 'membership_application' });
              }
            }
            // this.$router.push({ name: 'membership_application' });
            
            // this.copiedEmail = this.email;
            // this.showForm1 = false;
            // this.showForm2 = true;
        }else{
          this.myerrors = response.data.error
          this.showErrorMessage = true
          setTimeout(() => {this.showErrorMessage = false}, 1500);
          console.log("this.account__________________", this.account)
          localStorage.clear();
        }
      })
      .catch(error => {console.error('Error sending email:', error);});
    },
  }
};
</script>

<template>
   <div class="loader-main" id="make_it_load" v-if="make_it_load"><div class="loader"></div></div>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="card-body pt-0">
            <div>
              <!-- <router-link to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/ECU/ECU-logo.jpg" alt height="34" />
                  </span>
                </div>
              </router-link> -->
            </div>

            <div class="p-2">
                <div class="text-center py-3">
                    <img src="@/assets/images/ECU/ECU-logo.jpg" alt="ECU Logo" height="80" />
                </div>
              <b-alert
                v-model="isResetError"
                class="mb-4"
                variant="danger"
                dismissible
                >{{ error }}</b-alert
              >
              <!-- <h5>Customer Login</h5> -->
              <div v-show="showErrorMessage" class="mt-2 text-danger fw-bold"><v-alert color="error">{{myerrors}}</v-alert></div>
              <form @submit.prevent="tryToLogin()" v-if="showForm2">
                <div class="mb-3">
                  <label for="useremail">Email</label>
                  <input
                    type="email"
                    v-model="copiedEmail"
                    class="form-control"
                    id="useremail"
                    placeholder="Enter email"
                    :class="{ 'is-invalid': submitted && v$.email.$error }" :disabled="true"
                  />
                  <div
                    v-for="(item, index) in v$.email.$errors"
                    :key="index"
                    class="invalid-feedback"
                  >
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
                <div class="mb-3">
                    <label for="otpId">Password/OTP</label>
                    <input
                        type="text"
                        v-model="otp"
                        class="form-control"
                        id="otpId"
                        placeholder="Enter your password/otp"
                        :class="{
                      'is-invalid': submitted && v$.maxlength.$error,}"
                    />
                    <div
                        v-for="(item, index) in v$.maxlength.$errors"
                        :key="index"
                        class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
                </div>
                <div class="mb-3 row mb-0">
                  <div class="col-12">
                    <button class="btn btn-theme w-md" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <form @submit.prevent="tryToReset(true)" v-if="showForm1">
                <div class="mb-3">
                  <label for="useremail">Email</label>
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    id="useremail"
                    placeholder="Enter email"
                    :class="{ 'is-invalid': submitted && v$.email.$error }" :disabled="isDisabled" required 
                  />
                  <div v-if="isLoading">
                    <label for="useremail">Please wait...</label>
                  </div>
                </div>
                
                <div class="mb-3 row mb-0">
                  <div class="col-12">
                    <button class="btn btn-theme w-md" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            © {{ new Date().getFullYear() }} ECU. Crafted with by <a class="text-primary" target="blank" href="https://midriffinfosolution.org/">MISPL</a>
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>


<style lang="scss" module></style>
